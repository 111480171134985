<template>
  <div class="record">
    <!-- <van-tabs
        v-model="active"
        line-width="0"
        :class="[active == 0 ? 'tab-diy1' : 'tab-diy2']"
      >
        <van-tab title=""></van-tab>
        <van-tab title=""></van-tab>
      </van-tabs> -->
    <div class="big-white-bg">
      <div class="record-content">
        <div class="tabdiv">
          <div
            class="tab-item tab-item1"
            :class="{ on: type == 1 }"
            @click="type = 1"
          >
            <div>
              成功邀请:<b>{{ dataForm.validRecommendCount }}</b
              >人
            </div>
          </div>
          <div
            class="tab-item tab-item2"
            :class="{ on: type == 2 }"
            @click="type = 2"
          >
            <div>
              反馈总数/有效:<b
                >{{ dataForm.feedTotalCount }}/{{
                  dataForm.validFeedPackCount
                }}</b
              >
            </div>
          </div>
        </div>
        <div
          v-if="type == 1"
          style="flex: 1; display: flex; flex-direction: column"
        >
          <div class="record-content-title">
            <div class="record-content-title-item">手机号</div>
            <div class="record-content-title-item">注册日期</div>
          </div>
          <div class="list">
            <div
              class="list-content"
              ref="refInvite"
              v-if="recommendList.length > 0"
            >
              <div>
                <div
                  class="list-item"
                  v-for="(item, index) in recommendList"
                  :key="index"
                >
                  <div class="list-item-div">{{ item.inviteeMobile }}</div>
                  <div class="list-item-div">{{ item.recDateStr }}</div>
                </div>
              </div>
            </div>
            <div class="list-empty" v-else>
              <div class="list-empty-text">暂无数据</div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="record-content-title">
            <div class="record-content-title-item">反馈类型</div>
            <div class="record-content-title-item">反馈时间</div>
            <div class="record-content-title-item">反馈状态</div>
            <div class="record-content-title-item">反馈详情</div>
          </div>
          <div class="list">
            <div
              class="list-content"
              ref="refInvite"
              v-if="feedBackList.length > 0"
            >
              <div>
                <div
                  class="list-item"
                  v-for="(item, index) in feedBackList"
                  :key="index"
                >
                  <div class="list-item-div">
                    <span v-if="item.feedBackType == 1">功能问题</span>
                    <span v-if="item.feedBackType == 2">界面问题</span>
                    <span v-if="item.feedBackType == 3">体验问题</span>
                    <span v-if="item.feedBackType == 4">兼容性问题</span>
                    <span v-if="item.feedBackType == 5">性能问题</span>
                    <span v-if="item.feedBackType == 6">其它问题</span>
                  </div>
                  <div class="list-item-div">
                    {{ item.recDateStr.split(' ')[0] }}
                  </div>
                  <div class="list-item-div">
                    <span v-if="item.status == 0">审核中</span>
                    <span v-if="item.status == 1">审核中</span>
                    <span v-if="item.status == 2">审核中</span>
                    <span v-if="item.status == 3">有效</span>
                    <span v-if="item.status == 4">无效</span>
                  </div>
                  <div
                    class="list-item-div"
                    style="color: #3686f8"
                    @click="viewFeedbackDetails(item)"
                  >
                    查看
                  </div>
                </div>
              </div>
            </div>
            <div class="list-empty" v-else>
              <div class="list-empty-text">暂无数据</div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="text-align: center; margin-bottom: 20px"
        v-if="type == 2"
        @click="invitefeedback"
      >
        <img
          class="overlay_content_img"
          style="height: 58px"
          :src="invitefeedbackbtn"
          alt=""
        />
      </div>
    </div>
    <van-popup
      v-model:show="viewFeedbackShow"
      style="background-color: transparent; width: 100%"
      @closed="viewFeedbackClosed"
    >
      <div class="viewFeedback" v-if="viewFeedbackShow">
        <div class="viewFeedback-title"></div>
        <div
          class="viewFeedback-list"
          v-if="viewFeedbackDetailsFields.length > 0"
        >
          <div
            v-for="(item, index) in viewFeedbackDetailsFields"
            :key="index"
            class="viewFeedback-Fields"
            :style="{ alignItems: item.label === '反馈语音' ? 'center' : '' }"
          >
            <div class="feedback-title">
              <span>{{ item.label }}：</span>
            </div>
            <div class="feedback-content">
              <span
                v-if="item.label === '反馈图片' && item.value"
                style="display: flex; flex-wrap: wrap"
              >
                <div
                  v-for="(item1, index) in item.value.split(',')"
                  :key="index"
                >
                  <img
                    :src="item1"
                    alt=""
                    @click="imagePreview(item.value.split(','), index)"
                    style="
                      width: 100px;
                      height: 100px;
                      cursor: pointer;
                      margin-right: 5px;
                    "
                  />
                </div>
              </span>
              <span
                v-else-if="item.label === '反馈语音' && item.value"
                style="display: flex"
              >
                <audio
                  ref="audioPlayer"
                  :src="item.value"
                  @ended="onAudioEnded"
                ></audio>
                <img
                  src="../../assets/img/userFeedback/microphone.png"
                  alt=""
                  @click="handlePlay"
                />
                <div class="prompt">
                  <div class="small-triangle"></div>
                  <div class="prompt-content" @click="handlePlay">
                    <img
                      :src="
                        require(isPlay
                          ? '@/assets/img/userFeedback/soundRecording.gif'
                          : '@/assets/img/userFeedback/soundRecording.png')
                      "
                      style="width: 15px; height: 20px; margin-right: 5px"
                      alt=""
                    />
                    <span>语音文件</span>
                    <span>
                      {{
                        duration && duration >= 0.5
                          ? Math.round(duration) + 's'
                          : ''
                      }}
                    </span>
                  </div>
                </div>
              </span>
              <span
                v-else-if="
                  item.label === '反馈软件' || item.label === '反馈类型'
                "
              >
                {{
                  softwareSelection(
                    item.value,
                    item.label === '反馈软件' ? '00' : '01'
                  )
                }}
              </span>
              <span v-else>{{ item.value }}</span>
            </div>
          </div>
        </div>
        <div class="viewFeedback-empty" v-else>
          <img
            src="@/assets/img/userFeedback/noneInformation.png"
            class="viewFeedback-empty-img"
          />
        </div>
        <div class="viewFeedback-close" @click="onClickCloseRecord"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getRecommendList } from '@/api/invite';
import { feedBackDetails } from '@/api/userFeedback';
import { ImagePreview } from 'vant';
import Vue from 'vue';
export default Vue.extend({
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      duration: null,
      isPlay: false,
      viewFeedbackDetailsFields: [],
      viewFeedbackDetailsObj: {},
      viewFeedbackShow: false,
      invitefeedbackbtn: require('@/assets/img/invite/invite-feedback-btn.png'),
      title: '邀请记录',
      // inviteList: [
      //   { xx: '金融性问题', yy: '2020-02-22', zz: '审核中', kk: '查看' },
      //   { xx: '金融性问题', yy: '2020-02-22', zz: '审核中', kk: '查看' },
      //   { xx: '金融性问题', yy: '2020-02-22', zz: '审核中', kk: '查看' },
      //   { xx: '金融性问题', yy: '2020-02-22', zz: '审核中', kk: '查看' },
      //   { xx: '金融性问题', yy: '2020-02-22', zz: '审核中', kk: '查看' },
      //   { xx: '金融性问题', yy: '2020-02-22', zz: '审核中', kk: '查看' },
      //   { xx: '金融性问题', yy: '2020-02-22', zz: '审核中', kk: '查看' },
      //   { xx: '金融性问题', yy: '2020-02-22', zz: '审核中', kk: '查看' },
      //   { xx: '金融性问题', yy: '2020-02-22', zz: '审核中', kk: '查看' },
      //   { xx: '金融性问题', yy: '2020-02-22', zz: '审核中', kk: '查看' },
      //   { xx: '金融性问题', yy: '2020-02-22', zz: '审核中', kk: '查看' },
      // ],
      autoScrollTimeout: null,
      autoScrollInterval: null,
      inviterMobile: '',
      invitationMethod: '1',
      active: 0,
      type: 1,
      dataForm: {},
      feedBackList: [],
      recommendList: [],
    };
  },
  methods: {
    GetRecommendList() {
      getRecommendList(
        this.$store.getters.currentLoginMobile,
        this.$store.getters.inviteToken
      )
        .then((res) => {
          this.dataForm = res;
          this.feedBackList = res.feedBackList;
          this.recommendList = res.recommendList;
          // this.inviteList = (res ?? []).map((item) => {
          //   return {
          //     inviteeMobile: item.inviteeMobile,
          //     recDateStr: item.recDateStr,
          //   };
          // });
          // if (this.inviterMobile !== this.$store.getters.currentLoginMobile) {
          //   let currentUrl = window.location.href;
          //   if (currentUrl.indexOf("?") != -1) {
          //     let newUrl = currentUrl.substr(0, currentUrl.indexOf("?") + 1);
          //     let info = decodeURIComponent(
          //       JSON.stringify({
          //         inviterMobile: this.$store.getters.currentLoginMobile,
          //         invitationMethod: 1,
          //       })
          //     );
          //     window.history.replaceState(null, "", newUrl + `?info=${info}`);
          //   }
          // }
          this.$nextTick(() => {
            this.AutoScroll();
          });
        })
        .catch((error) => {
          if (
            error?.status === 400 ||
            error?.status === 401 ||
            error?.status === 403 ||
            error?.data?.status === 400 ||
            error?.data?.status === 401 ||
            error?.data?.status === 403
          ) {
            // if (this.inviterMobile !== this.$store.getters.currentLoginMobile) {
            //   this.$router.replace({
            //     path: `/openBetaInvite?inviterMobile=${this.inviterMobile}&invitationMethod=${this.invitationMethod}`,
            //   });
            // } else {
            //   this.$router.replace({
            //     path: `/openBetaInvite`,
            //   });
            // }
            this.$router.replace({
              path: `/openBetaInvite`,
            });
            localStorage.removeItem('inviteToken');
            localStorage.removeItem('currentLoginMobile');
            this.$store.commit('SET_INVITE_TOKEN', '');
            this.$store.commit('SET_CURRENT_LOGIN_MOBILE', '');
          }
        });
    },
    AutoScroll() {
      if (this.autoScrollInterval) {
        clearInterval(this.autoScrollInterval);
      }
      if (this.autoScrollTimeout) {
        clearTimeout(this.autoScrollTimeout);
      }
      console.log(this.$refs.refInvite.scrollTop);
      this.$nextTick(() => {
        this.autoScrollInterval = setInterval(() => {
          this.autoScrollTimeout = setTimeout(() => {
            this.$refs.refInvite.scrollTop = this.$refs.refInvite.scrollTop + 1;
            if (
              this.$refs.refInvite.clientHeight +
                this.$refs.refInvite.scrollTop >=
              this.$refs.refInvite.scrollHeight
            ) {
              this.$refs.refInvite.scrollTop = 0;
            }
          }, 1000);
        }, 60);
      });
    },
    // 音频播放完成回调
    onAudioEnded() {
      this.isPlay = false;
    },
    // 播放语音
    handlePlay() {
      this.isPlay = true;
      this.$refs.audioPlayer[0].play();
    },
    viewFeedbackDetails(value) {
      console.log(value, 'value');
      this.viewFeedbackShow = true;
      feedBackDetails(value.sequenceNbr, this.$store.getters.inviteToken)
        .then((res) => {
          this.viewFeedbackDetailsFields = [
            { label: '反馈软件', value: res.softWareType },
            { label: '反馈类型', value: res.feedBackType },
            { label: '反馈标题', value: res.title },
            { label: '反馈详情', value: res.detailContent },
            { label: '反馈语音', value: res.audioFileUrl },
            { label: '反馈图片', value: res.fileUrl },
          ];
          setTimeout(() => {
            this.duration = this.$refs.audioPlayer[0].duration;
          }, 200);
        })
        .finally(() => {
          console.log(1);
        });
    },
    // 弹窗关闭
    viewFeedbackClosed() {
      this.isPlay = false;
    },
    onClickCloseRecord() {
      this.viewFeedbackShow = false;
    },
    // 预览图片
    imagePreview(files, index) {
      ImagePreview({ images: files, startPosition: index });
    },
    softwareSelection(val, type) {
      switch (val) {
        case '1':
          return type == '00' ? '计价软件' : '功能问题';
        case '2':
          return type == '00' ? '算量软件' : '界面问题';
        case '3':
          return type == '00' ? '智能组价' : '体验问题';
        case '4':
          return type == '00' ? '标书检查' : '兼容性问题';
        case '5':
          return type == '00' ? '工程指标' : '性能问题';
        case '6':
          return type == '00' ? '材料信息' : '其他问题';
      }
    },
    invitefeedback() {
      this.$router.push({ path: `/userFeedback`, query: { within: 'true' } });
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    this.GetRecommendList();
    // if (this.$route.query?.info) {
    //   let info = (this.$route.query?.info ?? '').split('_');
    //   if (info.length > 1) {
    //     this.inviterMobile = (info?.[0] ?? '').replace('inviterMobile,', '');
    //     this.invitationMethod = '1';
    //     if (
    //       (this.$store.getters.inviteToken ?? '') !== '' &&
    //       this.$store.getters.currentLoginMobile !== this.inviterMobile
    //     ) {
    //       localStorage.removeItem('inviteToken');
    //       localStorage.removeItem('currentLoginMobile');
    //       this.$store.commit('SET_INVITE_TOKEN', '');
    //       this.$store.commit('SET_CURRENT_LOGIN_MOBILE', '');
    //       this.$router.replace({
    //         path: `/invite?inviterMobile=${this.inviterMobile}&invitationMethod=${this.invitationMethod}`,
    //       });
    //     } else {
    //       this.GetRecommendList();
    //     }
    //   }
    // } else {
    //   this.GetRecommendList();
    // }
  },
  // 查看反馈详情
  beforeDestroy() {
    if (this.autoScrollInterval) {
      clearInterval(this.autoScrollInterval);
    }
    if (this.autoScrollTimeout) {
      clearTimeout(this.autoScrollTimeout);
    }
  },
});
</script>
<style lang="less" scoped>
.record {
  width: 100%;
  position: relative;
  min-height: 100vh;
  background: url('~@/assets/img/invite/open-beta-invite_bg.png') center top
    no-repeat;
  background-size: 100%;

  .big-white-bg {
    margin: 0 15px;

    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  &-content {
    background-color: #fff;
    box-shadow: 0px 3px 17px 0px rgba(255, 12, 0, 0.2);
    border-radius: 10px;
    margin-top: 200px;
    margin-bottom: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    &-title {
      display: flex;
      align-items: center;
      margin: 0 20px;
      line-height: 30px;
      height: 30px;
      background: #ededec;
      &-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #391916;
      }
    }
    .list {
      flex: 1;
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      &-content {
        height: 280px !important;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 6px;
      }
      &-empty {
        height: 280px !important;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999999;
        font-size: 18px;
      }
      &-item {
        display: flex;
        align-items: center;
        line-height: 24px;
        &-div {
          flex: 1;
          font-size: 11px;
          color: #391916;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &-viewFeedback {
    position: absolute;
    right: 0px;
    top: 140px;
    z-index: 30;
    background-image: url('~@/assets/img/userFeedback/feedbackDetails.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 35px;
    height: 93px;
  }
}
.viewFeedback {
  width: 100%;
  padding: 0 30px;
  &-title {
    width: 225px;
    height: 34px;
    background-image: url('~@/assets/img/userFeedback/feedbackDetails.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  &-list {
    background-color: #ffffff;
    box-shadow: 0px 5px 4px 0px rgba(99, 155, 249, 0.2);
    border-radius: 10px;
    padding: 0 10px;
    padding-top: 40px;
    margin-top: -24px;
    min-height: 300px;
    padding-bottom: 20px;
  }
  &-Fields {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    .feedback-title span {
      display: inline-block;
      min-width: 70px;
    }
    .feedback-content {
      width: calc(100% - 78px);
      overflow-wrap: break-word;
      .prompt {
        display: flex;
        align-items: center;
        .small-triangle {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid #4e81ee;
          margin-right: -3px;
        }
        &-content {
          display: flex;
          align-items: center;
          font-size: 13px;
          color: white;
          background-color: #4e81ee;
          padding: 5px 15px;
          border-radius: 5px;
        }
      }
    }
  }
  &-empty {
    background-color: #ffffff;
    box-shadow: 0px 5px 4px 0px rgba(99, 155, 249, 0.2);
    border-radius: 10px;
    padding: 0 10px;
    padding-top: 40px;
    margin-top: -24px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-img {
      width: 160px;
    }
  }
  &-close {
    width: 30px;
    height: 30px;
    background-image: url('~@/assets/img/withdraw/close.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: 10px;
  }
}
.tab-diy1 {
  overflow: hidden;
  /deep/.van-tabs__nav {
    background: none;
    background: url('~@/assets/img/invite/open-beta-record-tab1.png') center top
      no-repeat;
    background-size: 100% 40px;
  }
}
.tab-diy2 {
  /deep/.van-tabs__nav {
    background: none;
    background: url('~@/assets/img/invite/open-beta-record-tab2.png') center top
      no-repeat;
    background-size: 100% 40px;
  }
}
.tabdiv {
  display: flex;
  height: 80px;
  .tab-item {
    height: 80px;
    flex: 1;
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;

    div {
      flex: 1;
      padding-top: 5px;
      text-align: center;
      b {
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
  .tab-item1 {
    background: url('~@/assets/img/invite/hyyq-none.png') center bottom
      no-repeat;
    background-size: 100% 72px;
    &.on {
      background: url('~@/assets/img/invite/hyyq-on.png') center bottom
        no-repeat;
      background-size: 100% 80px;
    }
  }
  .tab-item2 {
    background: url('~@/assets/img/invite/rjwtfk-none.png') center bottom
      no-repeat;
    background-size: 100% 72px;
    &.on {
      background: url('~@/assets/img/invite/rjwtfk-on.png') center bottom
        no-repeat;
      background-size: 100% 80px;
    }
  }
}
</style>
